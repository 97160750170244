import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/nonun/...Active Code/new-gatsby-site/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Reading Review`}</h2>
    <p>{`I know this week I said that I won't be reading, but I ended up reading a lot. I finished `}<a parentName="p" {...{
        "href": "https://www.amazon.com/Flipped-Wendelin-Van-Draanen/dp/0375825444/ref=sr_1_2?dchild=1&keywords=Flipped&qid=1606053495&sr=8-2"
      }}>{`Book/Flipped`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.amazon.com/Anna-French-Kiss-Stephanie-Perkins/dp/0142419400/ref=sr_1_1?dchild=1&keywords=Anna+and+the+french+kiss&qid=1606053614&sr=8-1"
      }}>{`Book/Anna and the French Kiss`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.amazon.com/Alchemist-Paulo-Coelho/dp/0062315005/ref=sr_1_1?dchild=1&keywords=The+Alchemist&qid=1606053482&sr=8-1"
      }}>{`Book/The Alchemist`}</a>{` and `}<a parentName="p" {...{
        "href": "https://www.amazon.com/Obstacle-Way-Ancient-Adversity-Advantage/dp/1781251495"
      }}>{`Book/The Obstacle Is the Way`}</a>{`. My notes for `}<a parentName="p" {...{
        "href": "https://www.amazon.com/Flipped-Wendelin-Van-Draanen/dp/0375825444/ref=sr_1_2?dchild=1&keywords=Flipped&qid=1606053495&sr=8-2"
      }}>{`Book/Flipped`}</a>{` and available `}<a parentName="p" {...{
        "href": "../books/flipped.mdx"
      }}>{`here`}</a>{`. Also, my notes for Book/How to take smart notes are available `}<a parentName="p" {...{
        "href": "../books/how-to-take-smart-notes.mdx"
      }}>{`here`}</a>{`.`}</p>
    <h2>{`Weekly favourites`}</h2>
    <p><strong parentName="p">{`A fiction book`}</strong>{` - '`}<a parentName="p" {...{
        "href": "https://www.amazon.com/Alchemist-Paulo-Coelho/dp/0062315005/ref=sr_1_1?dchild=1&keywords=The+Alchemist&qid=1606053482&sr=8-1"
      }}>{`The Alchemist`}</a>{`' is the book that I am going to talk about here. The book just has this cool theme to it with omens and stuff. My personal favourite part of the book will be when The Alchemist finally meets that boy in the oasis camp.`}</p>
    <p><strong parentName="p">{`Another book`}</strong>{` - I never imagined that I would like this book but I seriously loved it! The book's name is Book/Flipped and it is a Teen Romantic Novel and it is fun. You can see my summary `}<a parentName="p" {...{
        "href": "../books/flipped.mdx"
      }}>{`here`}</a>{`.`}</p>
    <h2>{`Quote of the week`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/cb25c6675c9bb4237a752e8797becbb4/0d1a4/Quote-8.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "43.99999999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsSAAALEgHS3X78AAABpElEQVQoz61S/2/SQBzdP68bmUqGIiBLFGT+uqiAIBo2CgroWEaBQBooDFpa2mtLtzo2bJ93p4FfzOIPXvLy3n3e517u204QBPhfg2XtMGGNW9DOj7HsFykKMMQcZ6ubh0k1oWz1qO7k4EkfOTOP9biDIoj4Dldql4fyQGfwCePSPpRKFMaX51BOn4LU49CEKOaVZ5iWIxwq1U4zwTWr69Uo9FoM6kkY13JtG2j0SpiVD6BWE1CFOBQhBtI4xOQkCrOexKwS49Bq1K/GuSaNJPcU2m8IB7ClyjZwORRgfXsJcpaBVk+BfH8N9+IN7FYGFq0tmmnozRRnk3rzr6/gnB/RfqobadjdLDx9sA3U2znIhYd8l0o5DLn0CAshglXrBQb5ELrvdyF9CGFU3Mf082P0s7sQ3z6AlN+DVHyCW8/98yj+70CPXGI5bWM568BVOnCmIr3kHp2LHOzCA0eGb4+wtkacfzJYQ/jOGP56BfZZNq9sEgvqfIHJpYKhPIGqGSC2y1nVTWgL8k9fhh+Z0fpuhfXtDe5WPyg8Zv9lgX8Pgk3oL68zi7xkoAZHAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "quote",
            "title": "quote",
            "src": "/static/cb25c6675c9bb4237a752e8797becbb4/5a190/Quote-8.png",
            "srcSet": ["/static/cb25c6675c9bb4237a752e8797becbb4/772e8/Quote-8.png 200w", "/static/cb25c6675c9bb4237a752e8797becbb4/e17e5/Quote-8.png 400w", "/static/cb25c6675c9bb4237a752e8797becbb4/5a190/Quote-8.png 800w", "/static/cb25c6675c9bb4237a752e8797becbb4/0d1a4/Quote-8.png 1036w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`Tweet of the week`}</h2>
    <blockquote className="twitter-tweet" data-dnt="true">
  <p lang="en" dir="ltr">
    If you think every day, write every day.
  </p>
  &mdash; Jack Butcher (@jackbutcher) <a href="https://twitter.com/jackbutcher/status/1328318760449691648?ref_src=twsrc%5Etfw">November 16, 2020</a>
    </blockquote>
    <h2>{`Books that I will be finishing this week`}</h2>
    <center>
      <p><a parentName="p" {...{
          "href": "https://www.amazon.com/Final-Empire-Mistborn-Book-One/dp/0575089911/ref=sr_1_1?dchild=1&keywords=Mistborn&qid=1606053879&s=electronics&sr=1-1"
        }}><span parentName="a" {...{
            "className": "gatsby-img-attributes",
            "style": {
              "display": "inline-block",
              "width": "325px",
              "height": "500px"
            }
          }}><span parentName="span" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "800px"
              }
            }}>{`
      `}<span parentName="span" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "165.5%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAhABQDASIAAhEBAxEB/8QAGQABAAMBAQAAAAAAAAAAAAAAAAECBAMF/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAfW54R6jCNEXrZZKOsCwD//EABwQAQADAAIDAAAAAAAAAAAAAAEAAhIDESAhIv/aAAgBAQABBQKtixr6ndimr4HlmSVdwAPc6SB4f//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BX//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BX//EACAQAAAFAwUAAAAAAAAAAAAAAAABEBEhAiBBIjEyUWH/2gAIAQEABj8CBk+U07lDCaZynqN0OIim3//EAB8QAAICAQQDAAAAAAAAAAAAAAERACExEEFh8CBRsf/aAAgBAQABPyEAI3f2ZpMBXGiAr6pGPTI4d2hW/VCZkkWcARYSYcE7lEzCGwTlhN3nMIRdeH//2gAMAwEAAgADAAAAEEwScwff/8QAFhEBAQEAAAAAAAAAAAAAAAAAECEA/9oACAEDAQE/EC6v/8QAFhEBAQEAAAAAAAAAAAAAAAAAECEA/9oACAECAQE/ECaP/8QAHxABAAMBAAICAwAAAAAAAAAAAQARITFBURBhcYGh/9oACAEBAAE/EHbPBvqjAFmbdAUu/U5Fvja1YouP6lpqEs67sBOMo080v+3L9FNlNrk6ygfcxgaQDoX8sCgXg/UtVArVS0EuvrtfL2f/2Q==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="span" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Mistborn: The Final Empire",
                "title": "Mistborn: The Final Empire",
                "src": "/static/2a814debbde988fa005123bf2db1b2c7/4b190/final-empire.jpg",
                "srcSet": ["/static/2a814debbde988fa005123bf2db1b2c7/e07e9/final-empire.jpg 200w", "/static/2a814debbde988fa005123bf2db1b2c7/066f9/final-empire.jpg 400w", "/static/2a814debbde988fa005123bf2db1b2c7/4b190/final-empire.jpg 800w", "/static/2a814debbde988fa005123bf2db1b2c7/e5166/final-empire.jpg 1200w", "/static/2a814debbde988fa005123bf2db1b2c7/ee732/final-empire.jpg 1549w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy"
              }}></img>{`
    `}</span></span></a></p>
      <p><a parentName="p" {...{
          "href": "https://www.amazon.com/Psychology-Money-Timeless-lessons-happiness/dp/0857197681"
        }}><span parentName="a" {...{
            "className": "gatsby-img-attributes",
            "style": {
              "display": "inline-block",
              "width": "325px",
              "height": "500px"
            }
          }}><span parentName="span" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "800px"
              }
            }}>{`
      `}<span parentName="span" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "154.50000000000003%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAMBAgQF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAHs0YpNBIqce+g4qZf/xAAcEAEAAwACAwAAAAAAAAAAAAABAAIDEiEQERP/2gAIAQEAAQUCbI/Rh2X6K29s1rzzyzuXiLOL4//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABcRAAMBAAAAAAAAAAAAAAAAAAABEBH/2gAIAQIBAT8Buo//xAAbEAACAwEBAQAAAAAAAAAAAAABEQACECExMv/aAAgBAQAGPwL5JE7Q4S1EDhq1KvgGe7//xAAdEAADAAICAwAAAAAAAAAAAAAAAREhMUFRYZHw/9oACAEBAAE/IYDzCEj4oaDkpDVEmWIft5kOMk9s4MLkHnZvRpHS6FXbXosw64f/2gAMAwEAAgADAAAAENQDPv/EABYRAAMAAAAAAAAAAAAAAAAAAAAQEf/aAAgBAwEBPxBw/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPxAX/8QAHxABAQADAAICAwAAAAAAAAAAAREAITFBUWGhgZHw/9oACAEBAAE/EOTQc+vxgCxlWB5EPvAr4WPTAA2Kc02/3vH5jXDfz7wEAqzeQgqDfSMxURTAzrU87blNkJ7McsBwG/2Y6rw75/WXrJout5//2Q==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="span" {...{
                "className": "gatsby-resp-image-image",
                "alt": "The-psychology-of-money",
                "title": "The-psychology-of-money",
                "src": "/static/a8d54fd709d47aee7696e4c78d553188/4b190/psychology-of-money.jpg",
                "srcSet": ["/static/a8d54fd709d47aee7696e4c78d553188/e07e9/psychology-of-money.jpg 200w", "/static/a8d54fd709d47aee7696e4c78d553188/066f9/psychology-of-money.jpg 400w", "/static/a8d54fd709d47aee7696e4c78d553188/4b190/psychology-of-money.jpg 800w", "/static/a8d54fd709d47aee7696e4c78d553188/e5166/psychology-of-money.jpg 1200w", "/static/a8d54fd709d47aee7696e4c78d553188/c58a3/psychology-of-money.jpg 1500w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy"
              }}></img>{`
    `}</span></span></a></p>
    </center>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      